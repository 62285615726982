import React from 'react'
import LayoutSimple from '../components/layout-simple'
import LogoSVG from './../assets/images/logo.svg'
import './picture.scss'
import SEO from "../components/seo"

const PicturePage = (props) => {
  return(
    <LayoutSimple>
      <SEO title="Happy Selfie" description="El fotomatón autónomo para tu evento" image="%happy_selfie_picture%" />
      <div className="picture-page-wrapper safe-area-expand">
        <LogoSVG className="brand"/>
        <figure>
          <img src="%happy_selfie_picture%" alt="Happyselfie"/>
        </figure>
      </div>
    </LayoutSimple>
  )
}

export default PicturePage